module.exports = {
  phone: "手机号",
  email: "邮箱",
  lang: "EN",
  usernameLogin: "用户名登录",
  username: '用户名',
  emailLogin: "邮箱登录",
  phoneLogin: "手机号登录",
  cancel: "取消",
  Scan_the_code_to_pay_attention_to_the_public_number: "扫码关注公众号",
  In_order_to_check_your_assets: "为了您可以更方便的查看自己名下的资产，建议您关注 'Meshmellow Study' 公众号",
  Thank_you_for_your_patience: '感谢您的耐心操作!',
  Then_you_can_feel_free_to: '接下来您可以随意操作了，马上开始吧~',
  Third_party_authorization: '第三方授权',
  To_make_it_easier: '为了更方便的管理您名下的资产,请按照以下步骤操作。',
  P_username: "请输入用户名",
  Submit_verification_code:'提交验证码',
  Please_use_wechat :'请使用微信扫描二维码关注',
  Press_and_hold_the_image:"长按图片保存或扫码关注",
  And_send:'并发送“获取绑定验证码”',
  P_psw: '请输入密码',
  P_psw6: '请输入密码 (6位以上)',
  P_phone: '请输入手机号',
  The_current_user_name_already_exists: '当前用户名已存在',
  P_code: '请输入验证码',
  verification_code: '验证码',
  P_email: '请输入邮箱',
  P_cpsw: "确认密码",
  P_npsw: '请输入新密码 (6位以上)',
  P_cnpsw: "确认新密码",
  thirdPatyLogin: '第三方登录',
  No_account_yet: '还没有账号',
  It_mainland_phone_number: '不是中国大陆手机号',
  Skip_this_step: '跳过此步骤',
  Go_to_Register: '去注册',
  Existing_account: '已有账号',
  Go_and_log_in: '去登录',
  Forgot_your_password: '忘记密码?',
  btn_login: "登录",
  btn_signUp: '创建账号',
  signUp_continue: '注册',
  allowIterm: "同意所有注册条款",
  detail: '(详情)',
  getCode: "发送验证码",
  ok_continue: '确定',
  password: '密码',
  Successful_merger:'合并成功',
  codeHasBeenSent: '验证码已发送，请注意查收',
  noName: "用户名不能为空",
  noPhone: "手机号不能为空",
  noEmail: "邮箱不能为空",
  noPsw: "请输入密码",
  noCofirmPsw: "请再次输入密码",
  Password_more_than_characters: '密码(6位以上)',
  Confirm_password: '确认密码',
  noCode: "验证码不能为空",
  nameErr: "用户名格式错误，长度不能超过20位",
  phoneErr: "手机号格式错误",
  emailErr: "邮箱格式错误",
  pswErr: "密码大于等于六个字符",
  confirmPswErr: "两次输入密码不一致",
  followUsOnWechat: '关注我们的微信公众号!',
  community: '社区',
  meshSchool: '在线课程',
  mainland: '手机号注册仅支持中国大陆手机，海外用户请使用邮箱进行注册',
  psc: "修改密码成功",
  sra: "注册成功",
  ctc: "需要勾选注册条款才能继续～",
  back: '返回',
  next: '下一步',
  accomplish: '完成',
  I_have_been_concerned: '我已关注',
  please_follow_these_steps: '*为了更方便的管理您名下的资产,请按照以下步骤操作.'
};