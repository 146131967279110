<template>
  <div
    class="botton"
    @click="to('https://beian.miit.gov.cn/#/Integrated/index')"
  >
    <span> 沪ICP备15028626号-4</span>
  </div>
</template>
<script>
export default {
  methods: {
    to(e) {
      location.href = e;
    },
  },
};
</script>
<style lang='scss' scoped>
.botton {
  display: flex;

  align-items: center;
  justify-content: center;
  /* margin: 0 auto; */
  span {
    color: #ccc;
    cursor: pointer;
    &:hover {
      // color: rgb(34, 34, 34);
    }
  }
}
</style>